<script setup lang="ts">
type colorOption = 'red' | 'green' | 'blue'

const props = defineProps({
    photoUrl: {
        type: String as PropType<string | null | undefined>,
        required: false,
    },
    name: {
        type: String,
        required: false,
    },
    color: {
        type: String as PropType<colorOption>,
        required: false,
    },
    ringSize: {
        type: Number,
        required: false,
        default: 2,
    },
    noTooltip: {
        type: Boolean,
        required: false,
        default: false,
    },
})

const placeholderImage = computed(() => {
    const background = props.color === 'red' ? 'FEF2F2' : props.color === 'blue' ? 'EFF6FF' : 'F0FDF4'
    const color = props.color === 'red' ? 'B91C1C' : props.color === 'blue' ? '1D4ED8' : '15803D'
    const params = {
        name: props.name,
        size: 128,
        background,
        color,
    }
    const paramString = Object.entries(params).map(([key, value]) => `${key}=${value}`).join('&')
    return `https://ui-avatars.com/api/?${paramString}`
})

const src = ref(props.photoUrl ?? placeholderImage)

const ringColor = computed(() => {
    switch (props.color) {
        case 'red':
            return 'ring-red-700'
        case 'blue':
            return 'ring-blue-700'
        default:
            return 'ring-green-700'
    }
})
</script>

<template>
    <UTooltip :prevent="noTooltip || !name" :text="name">
        <NuxtImg
            class="h-full w-full flex-none !rounded-full object-cover object-center" :class="[ringSize && ringColor ? `ring-${ringSize} ${ringColor} ring-offset-1` : '']"
            :alt="name"
            :src="src"
            :placeholder="placeholderImage"
            :background="color === 'red' ? 'bg-red-100' : color === 'blue' ? 'bg-blue-100' : 'bg-green-100'"
            @error="src = placeholderImage"
        />
    </UTooltip>
</template>
